<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-database-edit</v-icon>出荷指示データ出力</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="exportForm" v-model="validExportForm" lazy-validation>
        <v-container>
          <v-row dense>
            <v-col cols="2">
              <dp-date-picker
                type="date"
                label="出荷日"
                v-model="dataExportModel.shipDate"
                filled
                dense
                :disabled="selectData"
                :rules="!selectData ? [rules.required] : []"
              ></dp-date-picker>
            </v-col>
            <v-col cols="2" v-if="!isAmazon" class="ml-6">
              <v-switch
                label="事前出荷データのみ"
                v-model="dataExportModel.onlyPreData"
                inset
                dense
                :disabled="selectData"
                :rules="[rules.required]"
              ></v-switch>
            </v-col>
            <v-col cols="2">
              <v-switch
                label="選択出荷データのみ"
                v-model="selectData"
                inset
                dense
                :disabled="!this.inputModel.length > 0"
                :rules="[rules.required]"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="12" v-if="selectData">
              <ag-grid-vue
                :domLayout="domLayout"
                class="ag-theme-alpine"
                :defaultColDef="defaultColDef"
                :columnDefs="columnDefs"
                :alwaysShowHorizontalScroll="true"
                :suppressColumnVirtualisation="true"
                :pagination="true"
                paginationPageSize="10"
                :localeText="localeText"
                @grid-ready="onGridReady"
                :columnTypes="columnTypes"
                :getRowNodeId="(data) => (isAmazon ? data.cartonId : data.shippingInstructionId)"
                style="height: 100%"
              >
              </ag-grid-vue>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onCancelClick">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onDataExportClick">出力</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
      <span class="ml-5" style="user-select: none">{{ loadingMessage }}</span>
    </v-overlay>
  </v-card>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import { NumericColumn, DateColumn, FullDateColumn, CheckmarkColumn } from "../../models/ag-grid/columnTypes";
import Validation from "../../libs/validation";
import CafereoShippingStatuses from "../../consts/shippingStatus/CafereoShippingStatuses";
import TruckingCompany from "../../consts/TruckingCompany";
import RequestUtils from "../../utils/RequestUtils";

export default {
  name: "ShippingInstructionDataExport",
  props: ["isAmazon", "inputModel"],
  components: {
    AgGridVue,
  },
  data() {
    return {
      dataExportModel: {},
      selectData: false,
      validExportForm: null,
      rules: {
        required: Validation.required,
      },
      gridApi: null,
      domLayout: null,
      defaultColDef: null,
      columnDefs: null,
      localeText: AG_GRID_LOCALE_JA,
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpDateColumn: DateColumn,
        dpFullDateColumn: FullDateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
      },
      cafereoColumn: [
        { headerName: "伝票番号", field: "slipNo", pinned: "left" },
        { headerName: "受注ID", field: "orderId", pinned: "left" },
        {
          headerName: "出荷ステータス",
          field: "shippingStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: CafereoShippingStatuses.all() },
          valueGetter: (params) => CafereoShippingStatuses.of(params.data.shippingStatus),
          pinned: "left",
        },
        { headerName: "出荷日", field: "shipDate", type: "dpDateColumn", pinned: "left" },
        { headerName: "出荷希望", field: "isReady", type: "dpCheckmarkColumn", pinned: "left" },
        { headerName: "出荷場所", field: "storageName" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        {
          headerName: "Box入数",
          field: "inBoxQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "CT中Box入数",
          field: "inCtBoxQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "CT中pcs入数",
          field: "inCtPcsQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "CTsizeW",
          field: "ctSizeWidth",
          type: "dpNumericColumn",
        },
        {
          headerName: "CTsizeD",
          field: "ctSizeDepth",
          type: "dpNumericColumn",
        },
        {
          headerName: "CTsizeH",
          field: "ctSizeHeight",
          type: "dpNumericColumn",
        },
        { headerName: "受注数", field: "orderQuantity", type: "dpNumericColumn" },
        { headerName: "取引先名", field: "customerName" },
        { headerName: "配送CD", field: "deliveryCode" },
        { headerName: "配送先住所", field: "deliveryAddress" },
        { headerName: "届先法人名", field: "deliveryName1" },
        { headerName: " 届先名称", field: "deliveryName2" },
        {
          headerName: "移動出荷指示",
          field: "isMoving",
          type: "dpCheckmarkColumn",
          filterParams: { caption: { true: "移動出荷", false: "通常出荷" } },
        },
        {
          headerName: "カートン分割指定",
          field: "cartonNumber",
        },
        {
          headerName: "佐川指定",
          field: "useSagawa",
          type: "dpCheckmarkColumn",
          filterParams: { caption: { true: "指定あり", false: "指定なし" } },
        },
        {
          headerName: "チャーター指定",
          field: "useCharter",
          type: "dpCheckmarkColumn",
          filterParams: { caption: { true: "指定あり", false: "指定なし" } },
        },
        {
          headerName: "AM優先指定",
          field: "priorityAm",
          type: "dpCheckmarkColumn",
          filterParams: { caption: { true: "指定あり", false: "指定なし" } },
        },
        {
          headerName: "オリコン指定",
          field: "useOricon",
          type: "dpCheckmarkColumn",
          filterParams: { caption: { true: "指定あり", false: "指定なし" } },
        },
        {
          headerName: "ガチャ商品",
          field: "gachaProducts",
          type: "dpCheckmarkColumn",
          filterParams: { caption: { true: "指定あり", false: "指定なし" } },
        },
        {
          headerName: "同梱指定",
          field: "bundledDesignation",
          type: "dpCheckmarkColumn",
          filterParams: { caption: { true: "指定あり", false: "指定なし" } },
        },
        {
          headerName: "事前出荷指定(カートン出荷)",
          field: "preShipment",
          type: "dpCheckmarkColumn",
          filterParams: { caption: { true: "指定あり", false: "指定なし" } },
        },
        {
          headerName: "着払い出荷",
          field: "cashOnDelivery",
          type: "dpCheckmarkColumn",
          filterParams: { caption: { true: "指定あり", false: "指定なし" } },
        },
        {
          headerName: "要送状NO",
          field: "useShippingLabel",
          type: "dpCheckmarkColumn",
          filterParams: { caption: { true: "指定あり", false: "指定なし" } },
        },
        { headerName: "配送指定日", field: "specifiedDeliveryDate", type: "dpDateColumn" },
        {
          headerName: "配送先所要日数",
          field: "daysRequired",
          valueGetter: (params) => {
            if (params.data.daysRequired == 1) {
              return "翌日着";
            } else if (params.data.daysRequired > 1) {
              const daysParams = params.data.daysRequired - 1;
              return `中${daysParams}日着`;
            }
            return "";
          },
        },
        { headerName: "備考", field: "remarks" },
        { headerName: "更新日時", field: "updateDatetime", type: "dpDateColumn" },
        { headerName: "更新者", field: "updateUser" },
        { headerName: "登録日時", field: "createDatetime", type: "dpDateColumn" },
        { headerName: "登録者", field: "createUser" },
      ],
      amazonColumn: [
        {
          headerName: "出荷日",
          field: "shipDate",
          filter: "agDateColumnFilter",
          type: "dpDateColumn",
          pinned: "left",
        },
        {
          headerName: "倉庫",
          field: "delivery1",
        },
        { headerName: "カートン名", field: "cartonName" },
        {
          headerName: "レコード数",
          field: "recordCount",
          type: "dpNumericColumn",
        },
        { headerName: "SSCC", field: "sscc" },
        {
          headerName: "運送会社",
          field: "truckingCompany",
          filterParams: { options: TruckingCompany.all() },
          valueGetter: (params) => TruckingCompany.of(params.data.truckingCompany),
        },
        { headerName: "送り状NO", field: "invoiceNumber" },
      ],
      errorColmuns: [
        { headerName: "出荷ID", field: "shippingInstructionId" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      loadingMessage: "圧縮中です",
    };
  },
  beforeMount() {
    this.domLayout = "autoHeight";
    this.defaultColDef = {
      filter: "agTextColumnFilter",
      resizable: true,
      sortable: true,
      suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
      maxWidth: 300,
    };
    this.columnDefs = this.setColumnDefs();
    this.init(this.inputModel);
  },
  watch: {
    inputModel(inputModel) {
      this.init(inputModel);
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    setColumnDefs() {
      let columnDefs = [
        {
          headerName: "",
          filter: false,
          resizable: false,
          sortable: false,
        },
      ];
      columnDefs = columnDefs.concat(this.isAmazon ? this.amazonColumn : this.cafereoColumn);
      return columnDefs;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.init(this.inputModel);
      this.gridApi.setRowData(JSON.parse(JSON.stringify(this.inputModel)));
      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.columnApi.autoSizeColumns(allColumnIds);
      this.gridApi.refreshCells();
    },
    init(inputModel) {
      this.selectData = inputModel.length > 0 ? true : false;
      this.initDataExportModel();
    },
    initDataExportModel() {
      this.dataExportModel = {
        shipDate: null,
        onlyPreData: false,
      };
    },
    reset() {
      this.init(this.inputModel);
    },
    onCancelClick() {
      this.$emit("cancel");
    },
    async onDataExportClick() {
      if (this.validate() && this.validSelectData()) {
        try {
          this.loadingOn();
          var condition = {};
          if (this.selectData) {
            let limitSelectData = [];
            if (!this.isAmazon) {
              let shippingInstructionIdList = [];
              for (let model of this.inputModel) {
                shippingInstructionIdList.push(model.shippingInstructionId);
              }
              condition.shippingInstructionId = shippingInstructionIdList;
              limitSelectData = shippingInstructionIdList;
            } else if (this.isAmazon) {
              let cartonIdList = [];
              for (let model of this.inputModel) {
                cartonIdList.push(model.cartonId);
              }
              condition.cartonId = cartonIdList;
              limitSelectData = cartonIdList;
            }
            // 最大件数チェック
            const validDataSizeRet = await RequestUtils.validDataSize(
              "C040604",
              limitSelectData.length,
              (limitSize) => {
                this.$dialog.notify.error(`最大処理件数（${limitSize}件）オーバーのため処理出来ませんでした。`, {
                  timeout: 2300,
                });
                this.loadingOff();
              }
            );
            if (!validDataSizeRet) {
              return;
            }
          } else {
            condition.shipDate = this.dataExportModel.shipDate;
            condition.onlyPreShipment = this.dataExportModel.onlyPreData;
          }
          condition.amazonShipment = this.isAmazon;
          let exec = true;
          let headers = null;
          while (exec) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            let response = await this.$store.dispatch("shippingInstruction/outShippinginstruct", {
              contents: condition,
              header: headers,
            });
            switch (response.data?.header.resultCode) {
              case ApiStatus.consts.SUCCESS:
                var result = response.data.contents;
                this.$emit("exported", result.exportFilePath);
                this.reset();
                exec = false;
                break;
              case ApiStatus.consts.BUSINESS_ERROR:
                var messages = [];
                var message = "";
                Object.keys(response.data?.header.messages).forEach(function (key) {
                  messages.push(response.data?.header.messages[key]?.join("<br>"));
                });
                if (messages.length > 0) message = messages.join("<br>");
                if (message != "") {
                  this.$dialog.warning({
                    title: `出荷指示データ出力`,
                    text: message,
                    actions: ["OK"],
                  });
                }
                exec = false;
                break;
              case ApiStatus.consts.PROCESSING:
                headers = { InvokeId: response.data.header.invokeId };
                break;
              default:
                exec = false;
                this.redirectError();
                break;
            }
          }
        } catch (error) {
          console.error("ShippingInstructionDataExport::onDataExportClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    validate() {
      const isValid = this.$refs.exportForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      }
      return isValid;
    },
    validSelectData() {
      if (!this.selectData) return true;
      let shipDate = null;
      for (let node of this.inputModel) {
        if (shipDate == null) {
          shipDate = node.shipDate;
        }
        if (node.shipDate != shipDate) {
          this.$dialog.notify.error(`異なる出荷日が選択されています。`, { timeout: 2300 });
          return false;
        }
        shipDate = node.shipDate;
      }
      return true;
    },
  },
};
</script>
