<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-app-bar dense>
          <v-app-bar-title><v-icon>mdi-book-search</v-icon>受注取込</v-app-bar-title>
          <v-spacer></v-spacer>
          <v-form ref="searchForm" v-model="validSearchForm" lazy-validation>
            <search-conditions max-width="100%" @search="onBtnSearch" max-height="100%">
              <v-container>
                <v-row dense>
                  <v-col>
                    <dp-date-picker
                      type="date"
                      label="EDI取込日From"
                      v-model="searchModel.importDateFrom"
                      :rules="[rules.ediImportDateFromRule]"
                      dense
                    ></dp-date-picker>
                  </v-col>
                  <v-col>
                    <dp-date-picker
                      type="date"
                      label="EDI取込日To"
                      v-model="searchModel.importDateTo"
                      :rules="[rules.ediImportDateToRule]"
                      dense
                    ></dp-date-picker>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-checkbox label="未確定のみ" v-model="searchModel.onlyUnsettled" hide-details dense></v-checkbox>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-textarea
                      v-model="searchModel.jancodes"
                      class="mx-2"
                      :rules="[rules.maxLength(1400), rules.isJancodeSearchList]"
                      label="JANコード"
                      rows="1"
                      dense
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-textarea
                      v-model="searchModel.poNumbers"
                      class="mx-2"
                      :rules="[rules.maxLength(900), rules.isPoSearchList]"
                      label="PO"
                      rows="1"
                      dense
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </search-conditions>
          </v-form>
          <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
          <v-divider vertical></v-divider>
          <tooltip-icon-button v-if="allowedAction(['C080202'])" icon="mdi-download-network" @click="onReceiveClick"
            >EDI受信</tooltip-icon-button
          >
          <v-divider vertical></v-divider>
          <tooltip-icon-button
            v-if="allowedAction(['C080203'])"
            icon="mdi-lead-pencil"
            @click="onReplyClick"
            :disabled="selectionRows.length === 0"
            >回答入力</tooltip-icon-button
          >
          <v-dialog :value="replyDialog" max-width="1800px" persistent scrollable>
            <reply
              ref="Reply"
              :inputModel="selectionRows"
              @cancel="onReplySubmit"
              @entry="onReplySubmit"
              v-if="replyDialog"
            ></reply>
          </v-dialog>
          <tooltip-icon-button
            v-if="allowedAction(['C080204'])"
            icon="mdi-check-circle"
            @click="onCommitClick"
            :disabled="selectionRows.length === 0"
            >回答確定</tooltip-icon-button
          >
          <confirm-grid-dialog ref="confirmGrid" :autoClose="false" width="1100px" height="350px"></confirm-grid-dialog
          ><!--
           <tooltip-icon-button icon="mdi-calendar-star" @click="onHyconClick" :disabled="selectionRows.length === 0"
            >ハイコン設定</tooltip-icon-button
          >-->
          <tooltip-icon-button v-if="allowedAction(['C080205'])" icon="mdi-download" @click="onExportClick"
            >CSVダウンロード</tooltip-icon-button
          >
          <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
          <v-divider vertical></v-divider>
          <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="shownInfo"
            >詳細表示</tooltip-icon-toggle-button
          >
        </v-app-bar>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="1" class="mt-2">
          <v-card-text class="font-weight-bold ml-4 mb-4">
            確認数合計：{{ this.checkQuantityTotal.toLocaleString() }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card elevation="1" class="mt-2">
          <v-card-text class="font-weight-bold ml-4 mb-4">
            PO番号数：{{ this.poNumberCount.toLocaleString() }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row style="height: 95%">
      <v-col :style="gridStyle" :cols="shownInfo ? 9 : 12" v-show="!shownInfo || !infoMaximum">
        <ag-grid-vue
          :gridOptions="gridOptions"
          :rowData="orderRecords"
          class="ag-theme-alpine"
          style="height: 100%"
          @selection-changed="onSelectionChanged"
          @filter-changed="onfilterChanged"
        ></ag-grid-vue>
      </v-col>
      <v-col
        v-if="shownInfo"
        id="PurchaseOrderInfos"
        style="flex-basis: auto; display: flex"
        :cols="infoMaximum ? 12 : 3"
      >
        <v-divider vertical></v-divider>
        <purchase-order-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
        ></purchase-order-infos>
      </v-col>
    </v-row>
    <error-grid-dialog
      ref="errorGrid"
      width="80%"
      height="80%"
      title="回答確定"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-container>
</template>

<style>
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import { getDisplayDetails2, typeFormat } from "../../models/ag-grid/helpers";
import { NumericColumn, DateColumn, CheckmarkColumn, IncludeFilter } from "../../models/ag-grid/columnTypes";
import { BooleanFilter } from "../../components/ag-grid/filters";
import SearchConditions from "../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import AmazonStatuses from "../../consts/amazonStatus/BaseStatuses";
import Reply from "./../../components/amazon/Reply.vue";
import ConfirmGridDialog from "../../components/common/ConfirmGridDialog.vue";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import PurchaseOrderInfos from "./../../components/amazon/PurchaseOrderInfos.vue";
import RequestUtils from "../../utils/RequestUtils";

export default {
  name: "PurchaseOrderSearch",
  components: {
    AgGridVue,
    SearchConditions,
    TooltipIconButton,
    TooltipIconToggleButton,
    Reply,
    ConfirmGridDialog,
    ErrorGridDialog,
    PurchaseOrderInfos,
  },
  data() {
    return {
      bulk_id: null,
      shownInfo: false,
      infoMaximum: false,
      activeTab: null,
      gridStyle: { height: "95%" },
      rules: {
        maxLength: Validation.maxLength,
        isJancodeSearchList: Validation.isJancodeSearchList,
        isPoSearchList: Validation.isPoSearchList,
        ediImportDateFromRule: (value) => this.ediImportDateFromRules(value),
        ediImportDateToRule: (value) => this.ediImportDateToRules(value),
      },
      selectedPageSize: 10,
      gridOptions: {
        columnTypes: {
          dpDateColumn: DateColumn,
          dpCheckmarkColumn: CheckmarkColumn,
          dpNumericColumn: NumericColumn,
        },
        frameworkComponents: {
          dpBooleanFilter: BooleanFilter,
        },
        defaultColDef: {
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            newRowsAction: "keep",
          },
        },
        columnDefs: [
          {
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            filter: false,
            resizable: false,
            sortable: false,
            pinned: "left",
          },
          { headerName: "PO番号", field: "poNumber", pinned: "left" },
          { headerName: "JANCODE", field: "jancode", pinned: "left" },
          { headerName: "ASIN", field: "asin", pinned: "left" },
          {
            headerName: "仕入先略称",
            field: "supplierShortName",
          },
          { headerName: "タイトル", field: "title", filterParams: IncludeFilter },
          { headerName: "商品名", field: "productName", filterParams: IncludeFilter },
          {
            headerName: "単価",
            field: "unitPrice",
            type: "dpNumericColumn",
          },
          {
            headerName: "依頼数",
            field: "requestQuantity",
            type: "dpNumericColumn",
          },
          {
            headerName: "確認数",
            field: "checkQuantity",
            type: "dpNumericColumn",
          },
          { headerName: "発送予定", field: "shippingPlanDate", filter: "agDateColumnFilter", type: "dpDateColumn" },
          {
            headerName: "在庫状況",
            field: "amazonStockStatus",
            filterParams: { options: AmazonStatuses.all() },
            valueGetter: (params) => AmazonStatuses.of(params.data.amazonStockStatus),
          },
          { headerName: "備考", field: "remarks" },
          {
            headerName: "引当可能",
            field: "stockQuantity",
            type: "dpNumericColumn",
          },
          { headerName: "配送先", field: "shippingAddress" },
          {
            headerName: "納品開始",
            field: "deliveryStartDate",
            filter: "agDateColumnFilter",
            type: "dpDateColumn",
          },
          {
            headerName: "納品締切",
            field: "deliveryEndDate",
            filter: "agDateColumnFilter",
            type: "dpDateColumn",
          },
          {
            headerName: "発注締日",
            field: "orderClosingDate",
            filter: "agDateColumnFilter",
            type: "dpDateColumn",
          },
          { headerName: "発売日", field: "releaseDate" },
          {
            headerName: "販売状況",
            field: "resaleOrSoldout",
          },
          { headerName: "商品詳細", field: "productDetail" },
          { headerName: "注文種別", field: "amazonOrderType" },
          {
            headerName: "確定済み",
            field: "replyFixed",
            type: "dpCheckmarkColumn",
          },
          {
            headerName: "EDI取込日",
            field: "ediImportDatetime",
            filter: "agDateColumnFilter",
            type: "dpDateColumn",
          },
          { headerName: "EDI取込者", field: "ediImportUser" },
          { headerName: "ID", field: "id", hide: true },
          {
            headerName: "更新日",
            field: "updateDate",
            filter: "agDateColumnFilter",
            type: "dpDateColumn",
            hide: true,
          },
        ],
        rowSelection: "multiple",
        suppressCellSelection: true,
        pagination: true,
        paginationPageSize: 10,
        suppressColumnVirtualisation: true,
        enableCellTextSelection: true,
        localeText: AG_GRID_LOCALE_JA,
        getRowNodeId: (data) => data.id,
      },
      orderRecords: [],
      selectionRows: [],
      confirmSuccessRecords: [],
      searchModel: {
        importDateFrom: null,
        importDateTo: null,
        onlyUnsettled: true,
        jancodes: "",
        poNumbers: "",
        janCode: [],
        poNumber: [],
      },
      poNumberCount: 0,
      checkQuantityTotal: 0,
      replyDialog: false,
      replyModel: {
        poNumber: null,
        janCode: null,
        asin: null,
        productName: null,
        requestQuantity: null,
        checkQuantity: null,
        shippingPlanDate: null,
        stockStatus: null,
        remarks: null,
        replyFixed: null,
      },
      validSearchForm: null,
      detailColumns: [
        { headerName: "PO番号", field: "poNumber", pinned: "left" },
        { headerName: "JANCODE", field: "jancode", pinned: "left" },
        { headerName: "ASIN", field: "asin", pinned: "left" },
        { headerName: "タイトル", field: "title", pinned: "left" },
        { headerName: "商品名", field: "productName" },
        {
          headerName: "単価",
          field: "unitPrice",
          type: "dpNumericColumn",
        },
        {
          headerName: "依頼数",
          field: "requestQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "確認数",
          field: "checkQuantity",
          type: "dpNumericColumn",
        },
        { headerName: "発送予定", field: "shippingPlanDate", filter: "agDateColumnFilter", type: "dpDateColumn" },
        {
          headerName: "在庫状況",
          field: "amazonStockStatus",
          filterParams: { options: AmazonStatuses.all() },
          valueGetter: (params) => AmazonStatuses.of(params.data.amazonStockStatus),
        },
        { headerName: "備考", field: "remarks" },
        {
          headerName: "引当可能",
          field: "stockQuantity",
          type: "dpNumericColumn",
        },
        { headerName: "配送先", field: "shippingAddress" },
        {
          headerName: "納品開始",
          field: "deliveryStartDate",
          filter: "agDateColumnFilter",
          type: "dpDateColumn",
        },
        {
          headerName: "納品締切",
          field: "deliveryEndDate",
          filter: "agDateColumnFilter",
          type: "dpDateColumn",
        },
        {
          headerName: "発注締切日",
          field: "orderClosingDate",
          filter: "agDateColumnFilter",
          type: "dpDateColumn",
        },
        { headerName: "発売日", field: "releaseDate" },
        { headerName: "販売状況", field: "resaleOrSoldout", type: "dpCheckmarkColumn" },
        { headerName: "商品詳細", field: "productDetail" },
        { headerName: "注文種別", field: "amazonOrderType" },
        {
          headerName: "確定済み",
          field: "replyFixed",
          type: "dpCheckmarkColumn",
        },
        {
          headerName: "EDI取込日",
          field: "ediImportDatetime",
          filter: "agDateColumnFilter",
          type: "dpDateColumn",
        },
        { headerName: "EDI取込者", field: "ediImportUser" },
      ],
      confirmColumns: [
        { headerName: "PO番号", field: "poNumber", pinned: "left" },
        { headerName: "JANCODE", field: "jancode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        {
          headerName: "依頼数",
          field: "requestQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "確認数",
          field: "checkQuantity",
          type: "dpNumericColumn",
        },
        { headerName: "発送予定", field: "shippingPlanDate", filter: "agDateColumnFilter", type: "dpDateColumn" },
        {
          headerName: "在庫状況",
          field: "amazonStockStatus",
          filterParams: { options: AmazonStatuses.all() },
          valueGetter: (params) => AmazonStatuses.of(params.data.amazonStockStatus),
        },
        { headerName: "備考", field: "remarks" },
        { headerName: "ID", field: "id", hide: true },
        {
          headerName: "更新日",
          field: "updateDate",
          filter: "agDateColumnFilter",
          type: "dpDateColumn",
          hide: true,
        },
      ],
      errorColmuns: [
        { headerName: "ID", field: "id" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
    };
  },
  beforeMount() {
    this.gridOptions.paginationPageSize = this.globalPageSize;
  },
  mounted() {
    this.onSearchClick();
    // 画面解像度による画面サイズ取得
    this.gridStyle.height = this.gridHeightSize + "px";
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    globalPageSize(size) {
      this.gridOptions.api.paginationSetPageSize(size);
      this.handleResize();
    },
    gridHeightSize(value) {
      this.gridStyle.height = value + "px";
    },
  },
  computed: {
    selectedRow() {
      return this.selectionRows.length === 1 ? this.selectionRows[0] : null;
    },
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }
      return getDisplayDetails2(this.selectedRow.id, this.detailColumns, this.gridOptions.api);
    },
  },
  methods: {
    clearFilters() {
      this.gridOptions.api.setFilterModel(null);
      this.gridOptions.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    async onSearchClick() {
      try {
        this.loadingOn();
        this.selectionRows = [];
        // jancodeのリスト化
        this.searchModel.janCode = this.searchModel.jancodes
          .replaceAll(/\n+/g, " ")
          .split(" ")
          .filter((n) => n);
        // poNumbersのリスト化
        this.searchModel.poNumber = this.searchModel.poNumbers
          .replaceAll(/\n+/g, " ")
          .split(" ")
          .filter((n) => n);
        const response = await this.$store.dispatch("amazon/search", { searchCondition: this.searchModel });
        console.log("PurchaseOrderSearch::onSearchClick", response);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        if (result.over) {
          this.$dialog.warning({
            title: "受注取込一覧",
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.orders).length === 0) {
          this.$dialog.warning({
            title: "受注取込一覧",
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.gridOptions.api.setRowData(result.orders);
        this.onfilterChanged();
        const allColumnIds = this.gridOptions.columnApi.getAllColumns().map((column) => column.colId);
        this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
      } catch (error) {
        console.error("PurchaseOrderSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    ediImportDateFromRules(value) {
      if (value == null || this.searchModel.importDateTo == null) return true;
      if (moment(value).isAfter(this.searchModel.importDateTo)) return "EDI取込日Toより前の日付を指定してください";
      return true;
    },
    ediImportDateToRules(value) {
      if (value == null || this.searchModel.importDateFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.importDateFrom)) return "EDI取込日Fromより後の日付を指定してください";
      return true;
    },
    async onReceiveClick() {
      try {
        this.loadingOn();
        this.selectionRows = [];
        let exec = true;
        let headers = null;
        while (exec) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
          let response = await this.$store.dispatch("amazon/import", {
            header: headers,
          });
          switch (response.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.onSearchClick();
              exec = false;
              break;
            case ApiStatus.consts.PROCESSING:
              headers = { InvokeId: response.data.header.invokeId };
              break;
            default:
              exec = false;
              this.redirectError();
              break;
          }
        }
      } catch (error) {
        console.error("PurchaseOrderSearch::onReceiveClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onReplyClick() {
      this.replyDialog = true;
    },
    onReplySubmit() {
      this.onSearchClick();
      this.replyDialog = false;
    },
    onCommitClick() {
      this.confirmSuccessRecords = [];
      this.$refs.confirmGrid.open({
        title: "回答確定",
        columns: this.confirmColumns,
        records: this.selectionRows,
        onSubmit: async (records) => {
          try {
            this.loadingOn();
            let condition = [];
            records.forEach((record) => {
              condition.push({
                poNumber: record.poNumber,
                asin: record.asin,
                lastUpdateDatetime: record.updateDate,
              });
            });
            // 最大件数チェック
            const validDataSizeRet = await RequestUtils.validDataSize("C080204", condition.length, (limitSize) => {
              this.$dialog.notify.error(`最大処理件数（${limitSize}件）オーバーのため処理出来ませんでした。`, {
                timeout: 2300,
              });
              this.loadingOff();
            });
            if (!validDataSizeRet) {
              return;
            }
            // 100件毎にスライス
            const sliceByNumber = (array, number) => {
              const length = Math.ceil(array.length / number);
              return new Array(length).fill().map((_, i) => array.slice(i * number, (i + 1) * number));
            };
            this.bulk_id = null;
            let requestRecordsSplit = sliceByNumber(condition, 100);
            // 一覧更新レコード
            let confirmRows = [];
            let errorRows = [];

            // APIでマージして引数を保持
            for (let i = 0; i < requestRecordsSplit.length; i++) {
              const response = await this.$store.dispatch("amazon/bulkComfirm", {
                orders: requestRecordsSplit[i],
                bulkId: this.bulk_id,
              });

              let error = response.data?.header;
              switch (error.resultCode) {
                case ApiStatus.consts.SUCCESS:
                  console.log(response.data.contents);
                  this.bulk_id = response.data.contents.bulkId;
                  break;
                default:
                  this.redirectError();
                  break;
              }
            }
            let exec = true;
            let headers = null;
            while (exec) {
              var param = {
                bulkId: this.bulk_id,
              };
              await new Promise((resolve) => setTimeout(resolve, 1000));
              const response2 = await this.$store.dispatch("amazon/comfirm", {
                contents: param,
                header: headers,
              });
              console.log("PurchaseOrderSearch::onCommitClick", response2);
              console.log("PurchaseOrderSearch::onCommitClick", headers?.InvokeId);
              let error = response2.data?.header;
              switch (error.resultCode) {
                case ApiStatus.consts.SUCCESS:
                case ApiStatus.consts.BUSINESS_ERROR:
                case ApiStatus.consts.ALREADY_CHANGED:
                  // エラーメッセージ格納
                  if (error.messages) {
                    Object.keys(error.messages).forEach((key) => {
                      errorRows.push({
                        id: key,
                        errorMessage: error.messages[key],
                      });
                    });
                  }
                  // 一覧部の更新分のレコード設定
                  this.gridOptions.api.forEachNode((node) => {
                    let row = node.data;
                    errorRows.forEach((errorRow) => {
                      if (errorRow.id != row.id) {
                        this.confirmSuccessRecords.push(row);
                        confirmRows.push({ id: row.id });
                      }
                    });
                  });
                  exec = false;
                  break;
                case ApiStatus.consts.PROCESSING:
                  headers = { InvokeId: response2.data.header.invokeId };
                  break;
                default:
                  exec = false;
                  this.redirectError();
                  break;
              }
            }
            // 一覧部のレコードを更新
            // if (confirmRows.length > 0) this.gridOptions.api.applyTransaction({ update: confirmRows });
            // エラー表示
            if (errorRows.length > 0) {
              console.log("PurchaseOrderSearch::onCommitClick", errorRows);
              // 確認ダイアログから更新完了分のデータを削除する(画面閉じる？)
              this.$refs.errorGrid.open({ records: errorRows });
              this.$dialog.notify.error(`発注データ確定処理に失敗したデータが存在します。ご確認ください。`, {
                timeout: 2300,
              });
            } else {
              this.$dialog.notify.info(`選択された発注データを確定しました`, { timeout: 2300 });
              this.$refs.confirmGrid.close();
            }
          } catch (error) {
            console.error("PurchaseOrderSearch::onCommitClick", error);
            this.apiRequestError(error);
          } finally {
            this.onSearchClick();
            this.loadingOff();
          }
        },
      });
    },
    onExportClick() {
      var allColumnIds = [];
      this.gridOptions.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.gridOptions.api.exportDataAsCsv({
        allColumns: true,
        onlySelected: this.selectionRows.length > 0,
        fileName: `PO一覧.csv`,
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    onSelectionChanged() {
      this.selectionRows = this.gridOptions.api.getSelectedRows();
    },
    onRowDataChanged() {
      this.gridOptions.columnApi.autoSizeAllColumns();
    },
    onfilterChanged() {
      let tmpPoNumbers = [];
      this.poNumberCount = 0;
      this.checkQuantityTotal = 0;
      this.gridOptions.api.forEachNodeAfterFilter((row) => {
        if (row.data.poNumber) {
          tmpPoNumbers.push(row.data.poNumber);
        }
        if (row.data.checkQuantity) {
          this.checkQuantityTotal += row.data.checkQuantity;
        }
      });
      const poNumbers = tmpPoNumbers.filter((elm, index) => tmpPoNumbers.indexOf(elm) === index);
      this.poNumberCount = poNumbers.length;
    },
  },
};
</script>
