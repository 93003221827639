<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-lead-pencil</v-icon>回答入力</span>
    </v-card-title>
    <v-card-text>
      <v-form ref="transferForm" v-model="validTransferForm" lazy-validation>
        <v-container>
          <v-divider class="my-3"></v-divider>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field
                type="number"
                label="確認数"
                v-model="replyModel.checkQuantity"
                :rules="[rules.confirmNoRule, rules.maxLength(8)]"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                label="在庫状況"
                v-model="replyModel.amazonStockStatus"
                :items="StockList"
                filled
                dense
                attach
              ></v-select>
            </v-col>
            <v-col cols="12" sm="5">
              <dp-date-picker
                type="date"
                label="発送予定(有効期限)"
                v-model="replyModel.shippingPlanDate"
                filled
                dense
              ></dp-date-picker>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="備考"
                v-model="replyModel.remarks"
                :rules="[rules.maxLength(100)]"
                filled
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-container style="max-width: inherit">
        <v-col style="height: 300px">
          <ag-grid-vue
            :gridOptions="gridOptions"
            class="ag-theme-alpine"
            style="height: 100%"
            @grid-ready="onGridReady"
            @selection-changed="onSelectionChanged"
          ></ag-grid-vue>
        </v-col>
      </v-container>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onCancelClick">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onReflection">一括反映</v-btn>
      <v-btn color="primary" @click="onReplySubmit">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="updateErrorGrid"
      width="80%"
      height="80%"
      title="回答入力"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-card>
</template>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  DateColumn,
  CheckmarkColumn,
  EditableColumn,
  EditableDateColumn,
} from "../../models/ag-grid/columnTypes";
import { BooleanFilter } from "../../components/ag-grid/filters";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import Validation from "../../libs/validation";
import StockStatus from "../../consts/amazonStatus/BaseStatuses";
import { DateValueFormatter } from "../../models/ag-grid/valueFormatters";
import { DateCellEditor, NumericCellEditor, CustomDateCellEditor } from "../../components/ag-grid/cellEditors";

export default {
  name: "Reply",
  props: ["inputModel"],
  components: {
    AgGridVue,
    ErrorGridDialog,
    /* eslint-disable vue/no-unused-components */
    CustomDateCellEditor,
  },
  data() {
    return {
      gridApi: null,
      columnApi: null,
      validTransferForm: null,
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        isNumber: Validation.isNumber,
        isDate: Validation.isDate,
        confirmNoRule: (value) => this.confirmNoRules(value),
      },
      StockList: StockStatus.all(),
      selectedPageSize: 10,
      gridOptions: {
        columnTypes: {
          dpDateColumn: DateColumn,
          dpCheckmarkColumn: CheckmarkColumn,
          dpNumericColumn: NumericColumn,
          dpEditableDateColumn: EditableDateColumn,
          dpEditableColumn: EditableColumn,
        },
        frameworkComponents: {
          BooleanFilter,
          dpDateCellEditor: DateCellEditor,
          dpNumericCellEditor: NumericCellEditor,
          dpCellDatePicker: CustomDateCellEditor,
        },
        defaultColDef: {
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            newRowsAction: "keep",
          },
        },
        columnDefs: [
          {
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            filter: false,
            resizable: false,
            sortable: false,
            pinned: "left",
          },
          { headerName: "PO番号", field: "poNumber", pinned: "left" },
          { headerName: "JANCODE", field: "jancode" },
          { headerName: "ASIN", field: "asin" },
          { headerName: "タイトル", field: "title" },
          { headerName: "商品名", field: "productName" },
          {
            headerName: "単価",
            field: "unitPrice",
            type: "dpNumericColumn",
          },
          {
            headerName: "依頼数",
            field: "requestQuantity",
            type: "dpNumericColumn",
          },
          {
            headerName: "確認数",
            field: "checkQuantity",
            //type: ["dpNumericColumn", "dpEditableColumn"],
            type: "dpEditableColumn",
            editable: true,
          },
          {
            headerName: "発送予定",
            field: "shippingPlanDate",
            filter: "agDateColumnFilter",
            type: "dpEditableColumn",
            cellEditorFramework: "CustomDateCellEditor",
            valueFormatter: DateValueFormatter("YYYY/MM/DD"),
            cellStyle: { backgroundColor: "#FFEDB3" },
          },
          {
            headerName: "在庫状況",
            field: "amazonStockStatus",
            editable: true,
            cellStyle: { backgroundColor: "#FFEDB3" },
            cellEditor: "agSelectCellEditor",
            cellEditorParams: () => {
              return {
                values: StockStatus.all(false).map((conditionType) => {
                  return conditionType.value;
                }),
              };
            },
          },
          { headerName: "備考", field: "remarks", type: "dpEditableColumn", editable: true },
          {
            headerName: "引当可能",
            field: "assignableQuantity",
            type: "dpNumericColumn",
          },
          { headerName: "配送先", field: "shippingAddress" },
          {
            headerName: "納品開始",
            field: "deliveryStartDate",
            filter: "agDateColumnFilter",
            type: "dpDateColumn",
          },
          {
            headerName: "納品締切",
            field: "deliveryEndDate",
            filter: "agDateColumnFilter",
            type: "dpDateColumn",
          },
          {
            headerName: "発注締切日",
            field: "orderClosingDate",
            filter: "agDateColumnFilter",
            type: "dpDateColumn",
          },
          { headerName: "発売日", field: "releaseDate" },
          { headerName: "販売状況", field: "resaleOrSoldout" },
          { headerName: "商品詳細", field: "productDetail" },
          { headerName: "注文種別", field: "amazonOrderType" },
          {
            headerName: "確定済み",
            field: "replyFixed",
            type: "dpCheckmarkColumn",
          },
          {
            headerName: "EDI取込日",
            field: "ediImportDatetime",
            filter: "agDateColumnFilter",
            type: "dpDateColumn",
          },
          { headerName: "EDI取込者", field: "ediImportUser" },
          { headerName: "ID", field: "id", hide: true },
          {
            headerName: "更新日",
            field: "updateDate",
            filter: "agDateColumnFilter",
            type: "dpDateColumn",
            hide: true,
          },
        ],
        rowSelection: "multiple",
        alwaysShowHorizontalScroll: true,
        suppressCellSelection: true,
        pagination: true,
        paginationPageSize: 10,
        enableCellTextSelection: true,
        localeText: AG_GRID_LOCALE_JA,
        suppressColumnVirtualisation: true,
        getRowNodeId: (data) => data.id,
      },
      selectionRows: [],
      replyModel: {
        checkQuantity: null,
        shippingPlanDate: null,
        amazonStockStatus: null,
        remarks: null,
      },
      errorColmuns: [
        { headerName: "ID", field: "id" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      errorRows: [],
      updateSuccessRecords: [],
    };
  },
  beforeMount() {
    this.gridOptions.paginationPageSize = this.globalPageSize;
    this.init(this.inputModel);
  },
  watch: {
    inputModel(inputModel) {
      this.init(inputModel);
    },
    globalPageSize(size) {
      this.gridApi.paginationSetPageSize(size);
      this.handleResize();
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    async init(inputModel) {
      this.initReplyModel(inputModel);
    },
    initReplyModel(inputModel) {
      this.updateSuccessRecords = [];
      this.replyModel = {
        checkQuantity: null,
        shippingPlanDate: null,
        amazonStockStatus: null,
        remarks: null,
      };
      if (this.gridApi) this.gridApi.setRowData(inputModel);
    },
    reset() {
      this.init(this.inputModel);
    },
    onCancelClick() {
      this.$emit("cancel", this.updateSuccessRecords);
    },
    onReflection() {
      if (this.validate()) {
        let updateRows = [];
        this.selectionRows.forEach((selectionRow) => {
          selectionRow.amazonStockStatus = this.replyModel.amazonStockStatus
            ? this.replyModel.amazonStockStatus
            : selectionRow.amazonStockStatus;
          selectionRow.remarks = this.replyModel.remarks ? this.replyModel.remarks : selectionRow.remarks;
          if (!selectionRow.replyFixed) {
            selectionRow.checkQuantity = this.replyModel.checkQuantity
              ? this.replyModel.checkQuantity
              : selectionRow.checkQuantity;
            selectionRow.shippingPlanDate = this.replyModel.shippingPlanDate
              ? this.replyModel.shippingPlanDate
              : selectionRow.shippingPlanDate;
          }
          updateRows.push(selectionRow);
        });
        // レコードを更新
        this.gridApi.applyTransaction({ update: updateRows });
        this.$refs.transferForm.resetValidation();
      }
    },
    async onReplySubmit() {
      try {
        let condition = [];
        this.gridApi.forEachNode((node) => {
          let row = node.data;
          condition.push({
            poNumber: row.poNumber,
            asin: row.asin,
            checkQuantity: row.checkQuantity,
            amazonStockStatus: row.amazonStockStatus == null ? "" : row.amazonStockStatus,
            shippingPlanDate: moment(row.shippingPlanDate).isValid()
              ? moment(row.shippingPlanDate).format("YYYY-MM-DD")
              : row.shippingPlanDate,
            remarks: row.remarks == null ? "" : row.remarks,
            lastUpdateDatetime: row.updateDate,
          });
        });
        var isValid = this.validateRow(condition);
        // エラー表示
        if (this.errorRows.length > 0) {
          this.$refs.updateErrorGrid.open({ records: this.errorRows });
        }

        if (isValid) {
          this.loadingOn();
          condition.forEach((row) => {
            row.checkQuantity = Number(row.checkQuantity);
          });
          const response = await this.$store.dispatch("amazon/update", { answers: condition });
          let error = response.data?.header;
          console.log("Reply::onReplySubmit", response);
          let updateRows = [];
          this.errorRows = [];
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  this.errorRows.push({
                    id: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              // 一覧部の更新分のレコード設定
              this.gridApi.forEachNode((node) => {
                var row = node.data;
                this.errorRows.forEach((errorRow) => {
                  if (errorRow.id != row.id) {
                    this.updateSuccessRecords.push(row);
                    updateRows.push({ id: row.id });
                  }
                });
              });
              // エラー表示
              if (this.errorRows.length > 0) {
                // 確認ダイアログから更新した分を削除(エラーがある場合のみ)
                this.gridApi.applyTransaction({ remove: updateRows });
                this.$refs.updateErrorGrid.open({ records: this.errorRows });
                this.$dialog.notify.error(`発注回答の更新処理に失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              } else {
                this.$dialog.notify.info("発注回答を更新しました", { timeout: 2300 });
                this.$refs.updateErrorGrid.close();
                this.$emit("entry", this.updateSuccessRecords);
                this.reset();
              }
              break;
            default:
              this.redirectError();
              break;
          }
        }
      } catch (error) {
        console.error("Reply::onReplySubmit", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    validate() {
      const isValid = this.$refs.transferForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      }
      return isValid;
    },
    confirmNoRules(value) {
      if (value == null) return true;
      if (isNaN(value)) return "数字を入力してください";
      return true;
    },
    onSelectionChanged() {
      this.selectionRows = this.gridApi.getSelectedRows();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.init(this.inputModel);
      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.columnApi.autoSizeColumns(allColumnIds);
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
    validateRow(rows) {
      var ret = true;

      // ASIN
      rows.forEach((row) => {
        console.log("row:", row);
        var messages = [];
        this.setValidMessage(this.rules.isNumber(row.checkQuantity), "確認数", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.checkQuantity), "確認数", messages);
        if (row.checkQuantity != 0) {
          this.setValidMessage(this.rules.required(row.shippingPlanDate), "発送予定", messages);
        }
        this.setValidMessage(this.rules.isDate(row.shippingPlanDate), "発送予定", messages);
        this.setValidMessage(this.rules.maxLength(100)(row.remarks), "備考", messages);
        if (messages.length > 0) {
          this.errorRows.push({
            id: row.poNumber,
            errorMessage: messages,
          });
          ret = false;
        }
      });
      return ret;
    },
  },
};
</script>
