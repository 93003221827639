<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-app-bar dense>
          <v-app-bar-title><v-icon>mdi-bullhorn</v-icon>出荷通知</v-app-bar-title>
          <v-spacer></v-spacer>
          <tooltip-icon-button
            v-if="allowedAction(['C080503'])"
            icon="mdi-check-circle"
            @click="onCommitClick"
            :disabled="selectionRows.length === 0"
            >確定</tooltip-icon-button
          >
          <confirm-grid-dialog ref="confirmGrid" :autoClose="false" width="1100px" height="350px"></confirm-grid-dialog>
          <tooltip-icon-button v-if="allowedAction(['C080504'])" icon="mdi-download" @click="onExportClick"
            >CSVダウンロード</tooltip-icon-button
          >
          <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
          <v-divider vertical></v-divider>
          <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="shownInfo"
            >詳細表示</tooltip-icon-toggle-button
          >
        </v-app-bar>
      </v-col>
    </v-row>
    <v-row style="height: 95%">
      <v-col :style="gridStyle" :cols="shownInfo ? 9 : 12" v-show="!shownInfo || !infoMaximum">
        <ag-grid-vue
          :gridOptions="gridOptions"
          :rowData="notifyRecords"
          class="ag-theme-alpine"
          style="height: 100%"
          @selection-changed="onSelectionChanged"
        ></ag-grid-vue>
      </v-col>
      <v-col
        v-if="shownInfo"
        id="ShippingNotificationInfos"
        style="flex-basis: auto; display: flex"
        :cols="infoMaximum ? 12 : 3"
      >
        <v-divider vertical></v-divider>
        <shipping-notification-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
        ></shipping-notification-infos>
      </v-col>
    </v-row>
    <error-grid-dialog
      ref="errorGrid"
      width="80%"
      height="80%"
      title="事前出荷通知確定"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-container>
</template>

<style>
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
//import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import { getDisplayDetails2, typeFormat } from "../../models/ag-grid/helpers";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import { NumericColumn, DateColumn, FullDateColumn } from "../../models/ag-grid/columnTypes";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import ConfirmGridDialog from "../../components/common/ConfirmGridDialog.vue";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import ShippingNotificationInfos from "./../../components/amazon/ShippingNotificationInfos.vue";
import RequestUtils from "../../utils/RequestUtils";

export default {
  name: "ShippingNotification",
  components: {
    AgGridVue,
    TooltipIconButton,
    TooltipIconToggleButton,
    ConfirmGridDialog,
    ErrorGridDialog,
    ShippingNotificationInfos,
  },
  data: () => ({
    shownInfo: false,
    infoMaximum: false,
    activeTab: null,
    selectedPageSize: 10,
    gridOptions: {
      columnTypes: {
        dpDateColumn: DateColumn,
        dpNumericColumn: NumericColumn,
        dpFullDateColumn: FullDateColumn,
      },
      frameworkComponents: {},
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: "agTextColumnFilter",
        filterParams: {
          newRowsAction: "keep",
        },
      },
      columnDefs: [
        {
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          filter: false,
          resizable: false,
          sortable: false,
          pinned: "left",
        },
        { headerName: "PO番号", field: "po", pinned: "left" },
        { headerName: "JANCODE", field: "jancode", pinned: "left" },
        { headerName: "RecID", field: "recId", pinned: "left" },
        { headerName: "RyohanCD", field: "ryohanCd" },
        { headerName: "DataKind", field: "dataKind" },
        { headerName: "ReceiveDate", field: "receiveDate", filter: "agDateColumnFilter", type: "dpDateColumn" },
        { headerName: "SEQ1", field: "seq1" },
        { headerName: "SEQ2", field: "seq2" },
        { headerName: "SEQ1REC", field: "seq1Rec" },
        { headerName: "SEQ2NEW", field: "seq1Rec" },
        { headerName: "OrderDetailNo", field: "orderDetailNo" },
        { headerName: "OrderDetailNo2", field: "orderDetailNo2" },
        { headerName: "OrderDetailUnitNo", field: "orderDetailUnitNo" },
        { headerName: "OrderDetailUnitNo2", field: "orderDetailUnitNo2" },
        { headerName: "MakerCD", field: "makerCd" },
        { headerName: "CommodityGTIN", field: "commodityGtin" },
        { headerName: "CommodityCD", field: "commodityCd" },
        { headerName: "MyCommodityCD", field: "myCommodityCd" },
        { headerName: "MyCommodityCD2", field: "myCommodityCd2" },
        { headerName: "MyCommodityCDType", field: "myCommodityCdType" },
        { headerName: "CommodityName", field: "commodityName" },
        { headerName: "CommodityNameK", field: "commodityNameK" },
        { headerName: "CommoditySpec", field: "commoditySpec" },
        { headerName: "CommoditySpecK", field: "commoditySpecK" },
        { headerName: "ColorCD", field: "colorCd" },
        { headerName: "SizeCD", field: "sizeCd" },
        { headerName: "ColorName", field: "colorName" },
        { headerName: "ColorNameK", field: "colorNameK" },
        { headerName: "MyColorCD", field: "myColorCd" },
        { headerName: "MyColorName", field: "myColorName" },
        { headerName: "SizeName", field: "sizeName" },
        { headerName: "SizeNameK", field: "sizeNameK" },
        { headerName: "MySizeCD", field: "mySizeCd" },
        { headerName: "MySizeName", field: "mySizeName" },
        { headerName: "CommodityClassificationS", field: "commodityClassificationS" },
        { headerName: "CommodityClassificationSS", field: "commodityClassificationSs" },
        {
          headerName: "DeliveryScheduledDate",
          field: "deliveryScheduledDate",
          filter: "agDateColumnFilter",
          type: "dpDateColumn",
        },
        {
          headerName: "DeliveryDeadline",
          field: "deliveryDeadline",
          filter: "agDateColumnFilter",
          type: "dpDateColumn",
        },
        { headerName: "Quantity", field: "quantity" },
        { headerName: "UnitOfIssue", field: "unitOfIssue" },
        { headerName: "UnitQuantity", field: "unitQuantity" },
        { headerName: "MeasureCode", field: "measureCd" },
        { headerName: "MeasureName", field: "measureName" },
        { headerName: "PackingFormCD", field: "packingFormCd" },
        { headerName: "PackingFormName", field: "packingFormName" },
        { headerName: "ShipmentUnitQuantity", field: "shipmentUnitQuantity" },
        { headerName: "AcceptedQuantity", field: "acceptedQuantity" },
        { headerName: "AcceptedUnitQuantity", field: "acceptedUnitQuantity" },
        { headerName: "ReturnedQuantity", field: "returnedQuantity" },
        { headerName: "CostUnitPrice", field: "costUnitPrice" },
        { headerName: "CostPrice", field: "costPrice" },
        { headerName: "SaleUnitPrice", field: "saleUnitPrice" },
        { headerName: "SalePrice", field: "salePrice" },
        { headerName: "OtherUnitPrice", field: "otherUnitPrice" },
        { headerName: "OtherPrice", field: "otherPrice" },
        { headerName: "TaxPrice", field: "taxPrice" },
        { headerName: "CenterInstruction", field: "centerInstruction" },
        { headerName: "ReasonCD", field: "reasonCd" },
        { headerName: "CorrectionDiv", field: "correctionDiv" },
        { headerName: "StockoutDiv", field: "stockoutDiv" },
        { headerName: "StockoutDivName", field: "stockoutDivName" },
        { headerName: "DetailComment", field: "detailComment" },
        { headerName: "DetailComment2", field: "detailComment2" },
        { headerName: "CorrectionComment", field: "correctionComment" },
        { headerName: "CorrectionComment2", field: "correctionComment2" },
        { headerName: "Charactor1", field: "charactor1" },
        { headerName: "Charactor2", field: "charactor2" },
        { headerName: "Charactor3", field: "charactor3" },
        { headerName: "Charactor4", field: "charactor4" },
        { headerName: "Charactor5", field: "charactor5" },
        { headerName: "Charactor6", field: "charactor6" },
        { headerName: "Charactor7", field: "charactor7" },
        { headerName: "Charactor8", field: "charactor8" },
        { headerName: "Charactor9", field: "charactor9" },
        { headerName: "Charactor10", field: "charactor10" },
        { headerName: "Number1", field: "number1" },
        { headerName: "Number2", field: "number2" },
        { headerName: "Number3", field: "number3" },
        { headerName: "Number4", field: "number4" },
        { headerName: "Number5", field: "number5" },
        { headerName: "Number6", field: "number6" },
        { headerName: "Number7", field: "number7" },
        { headerName: "Number8", field: "number8" },
        { headerName: "Number9", field: "number9" },
        { headerName: "Number10", field: "number10" },
        { headerName: "UnitMultiplePrintFLG", field: "unitMultiplePrintFlg" },
        { headerName: "SalePricePrintFLG", field: "salePricePrintFlg" },
        { headerName: "ClaimConversionFLG", field: "claimConversionFlg" },
        { headerName: "ReceiveDataFLG", field: "receiveDataFlg" },
        { headerName: "OrderDataFLG", field: "orderDataFlg" },
        { headerName: "DeleteFLG", field: "deleteFlg" },
        { headerName: "OriginalFLG", field: "originalFlg" },
        { headerName: "FLG1", field: "flg1" },
        { headerName: "FLG2", field: "flg2" },
        { headerName: "FLG3", field: "flg3" },
        { headerName: "InputDiv", field: "inputDiv" },
        { headerName: "MakingDate", field: "makingDate", filter: "agDateColumnFilter", type: "dpDateColumn" },
        { headerName: "EditDate", field: "editDate", filter: "agDateColumnFilter", type: "dpDateColumn" },
        { headerName: "EditUserID", field: "editUserId" },
        { headerName: "EditPCID", field: "editPcId" },
        { headerName: "EditProgramID", field: "editProgramId" },
        { headerName: "EditOSUserID", field: "editOsUserId" },
        { headerName: "EditUserComputerName", field: "editUserComputerName" },
        { headerName: "ProduceCommodityCD", field: "produceCommodityCd" },
        { headerName: "PrefectureCode", field: "prefectureCd" },
        { headerName: "CountryCode", field: "countryCd" },
        { headerName: "ProductionArea", field: "productionArea" },
        { headerName: "WaterZoneCode", field: "waterZoneCd" },
        { headerName: "WaterZoneName", field: "waterZoneName" },
        { headerName: "OriginArea", field: "originArea" },
        { headerName: "CommodityGrade", field: "commodityGrade" },
        { headerName: "CommoditySizeClass", field: "commoditySizeClass" },
        { headerName: "CommodityBrand", field: "commodityBrand" },
        { headerName: "CommodityPromotion", field: "commodityPromotion" },
        { headerName: "BiotechnologyKbn", field: "biotechnologyKbn" },
        { headerName: "UnitWeightCode", field: "unitWeightCd" },
        { headerName: "CommodityWeight", field: "commodityWeight" },
        { headerName: "PackingQuantity", field: "packingQuantity" },
        { headerName: "BreedCode", field: "breedCd" },
        { headerName: "CultivationDiv", field: "cultivationDiv" },
        { headerName: "DefrostDiv", field: "defrostDiv" },
        { headerName: "PreservationDiv", field: "preservationDiv" },
        { headerName: "ShapeCode", field: "shapeCd" },
        { headerName: "Usage", field: "usage" },
        { headerName: "OriginOrderNo", field: "originOrderNo" },
        { headerName: "OriginOrderDetailNo", field: "originOrderDetailNo" },
        { headerName: "ShipmentManageDetailNo", field: "shipmentManageDetailNo" },
        { headerName: "OrderWeight", field: "orderWeight" },
        { headerName: "UnitWeight", field: "unitWeight" },
        { headerName: "ShipmentWeight", field: "shipmentWeight" },
        { headerName: "AcceptedWeight", field: "acceptedWeight" },
        { headerName: "ReturnedWeight", field: "returnedWeight" },
        { headerName: "TemporaryDiv", field: "temporaryDiv" },
        { headerName: "StatuteClassifyDiv", field: "statuteClassifyDiv" },
        { headerName: "FLG4", field: "flg4" },
        { headerName: "FLG5", field: "flg5" },
        { headerName: "FLG6", field: "flg6" },
        { headerName: "SendConversionFLG", field: "sendConversionFlg" },
        { headerName: "WorkplaceID", field: "workplaceId" },
        { headerName: "DataKey", field: "dataKey" },
        { headerName: "配送センター", field: "deliveryCenter" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        { headerName: "発注締切", field: "orderClosingDate", filter: "agDateColumnFilter", type: "dpDateColumn" },
        { headerName: "発売日", field: "releaseDate" },
        { headerName: "販売状況", field: "soldout" },
        { headerName: "商品明細", field: "productDetail" },
        { headerName: "Box入数", field: "inBoxQuantity", type: "dpNumericColumn" },
        { headerName: "在庫数", field: "stockQuantity", type: "dpNumericColumn" },
        { headerName: "OrderDiv", field: "orderDiv" },
        { headerName: "OrderDiv2", field: "orderDiv2" },
        { headerName: "ShipCD", field: "shipCd" },
        { headerName: "SSCC", field: "sscc" },
        { headerName: "備考", field: "remarks" },
        { headerName: "納品開始", field: "deliveryStartDate", filter: "agDateColumnFilter", type: "dpDateColumn" },
        { headerName: "納品締切", field: "deliveryEndDate", filter: "agDateColumnFilter", type: "dpDateColumn" },
        { headerName: "ShipmentQuantity", field: "shipmentQuantity", type: "dpNumericColumn", pinned: "right" },
        { headerName: "ID", field: "id", hide: true },
      ],
      rowSelection: "multiple",
      suppressCellSelection: true,
      suppressColumnVirtualisation: true,
      pagination: true,
      paginationPageSize: 10,
      enableCellTextSelection: true,
      localeText: AG_GRID_LOCALE_JA,
      getRowNodeId: (data) => data.id,
    },
    detailColumns: [
      { headerName: "RecID", field: "recId" },
      { headerName: "RyohanCD", field: "ryohanCd" },
      { headerName: "DataKind", field: "dataKind" },
      { headerName: "ReceiveDate", field: "receiveDate", filter: "agDateColumnFilter", type: "dpDateColumn" },
      { headerName: "SEQ1", field: "sEQ1" },
      { headerName: "SEQ2", field: "sEQ2" },
      { headerName: "SEQ1REC", field: "sEQ1REC" },
      { headerName: "SEQ2NEW", field: "sEQ2NEW" },
      { headerName: "OrderDetailNo", field: "orderDetailNo" },
      { headerName: "OrderDetailNo2", field: "orderDetailNo2" },
      { headerName: "OrderDetailUnitNo", field: "orderDetailUnitNo" },
      { headerName: "OrderDetailUnitNo2", field: "orderDetailUnitNo2" },
      { headerName: "MakerCD", field: "makerCD" },
      { headerName: "CommodityGTIN", field: "commodityGTIN" },
      { headerName: "CommodityCD", field: "commodityCD" },
      { headerName: "MyCommodityCD", field: "myCommodityCD" },
      { headerName: "MyCommodityCD2", field: "myCommodityCD2" },
      { headerName: "MyCommodityCDType", field: "myCommodityCDType" },
      { headerName: "CommodityName", field: "commodityName" },
      { headerName: "CommodityNameK", field: "commodityNameK" },
      { headerName: "CommoditySpec", field: "commoditySpec" },
      { headerName: "CommoditySpecK", field: "commoditySpecK" },
      { headerName: "ColorCD", field: "colorCD" },
      { headerName: "SizeCD", field: "sizeCD" },
      { headerName: "ColorName", field: "colorName" },
      { headerName: "ColorNameK", field: "colorNameK" },
      { headerName: "MyColorCD", field: "myColorCD" },
      { headerName: "MyColorName", field: "myColorName" },
      { headerName: "SizeName", field: "sizeName" },
      { headerName: "SizeNameK", field: "sizeNameK" },
      { headerName: "MySizeCD", field: "mySizeCD" },
      { headerName: "MySizeName", field: "mySizeName" },
      { headerName: "CommodityClassificationS", field: "commodityClassificationS" },
      { headerName: "CommodityClassificationSS", field: "commodityClassificationSS" },
      {
        headerName: "DeliveryScheduledDate",
        field: "deliveryScheduledDate",
        filter: "agDateColumnFilter",
        type: "dpDateColumn",
      },
      {
        headerName: "DeliveryDeadline",
        field: "deliveryDeadline",
        filter: "agDateColumnFilter",
        type: "dpDateColumn",
      },
      { headerName: "Quantity", field: "quantity" },
      { headerName: "UnitOfIssue", field: "unitOfIssue" },
      { headerName: "UnitQuantity", field: "unitQuantity" },
      { headerName: "MeasureCode", field: "measureCode" },
      { headerName: "MeasureName", field: "measureName" },
      { headerName: "PackingFormCD", field: "packingFormCD" },
      { headerName: "PackingFormName", field: "packingFormName" },
      { headerName: "ShipmentUnitQuantity", field: "shipmentUnitQuantity" },
      { headerName: "ShipmentQuantity", field: "shipmentQuantity", type: "dpNumericColumn" },
      { headerName: "AcceptedQuantity", field: "acceptedQuantity" },
      { headerName: "AcceptedUnitQuantity", field: "acceptedUnitQuantity" },
      { headerName: "ReturnedQuantity", field: "returnedQuantity" },
      { headerName: "CostUnitPrice", field: "costUnitPrice" },
      { headerName: "CostPrice", field: "costPrice" },
      { headerName: "SaleUnitPrice", field: "saleUnitPrice" },
      { headerName: "SalePrice", field: "salePrice" },
      { headerName: "OtherUnitPrice", field: "otherUnitPrice" },
      { headerName: "OtherPrice", field: "otherPrice" },
      { headerName: "TaxPrice", field: "taxPrice" },
      { headerName: "CenterInstruction", field: "centerInstruction" },
      { headerName: "ReasonCD", field: "reasonCD" },
      { headerName: "CorrectionDiv", field: "correctionDiv" },
      { headerName: "StockoutDiv", field: "stockoutDiv" },
      { headerName: "StockoutDivName", field: "stockoutDivName" },
      { headerName: "DetailComment", field: "detailComment" },
      { headerName: "DetailComment2", field: "detailComment2" },
      { headerName: "CorrectionComment", field: "correctionComment" },
      { headerName: "CorrectionComment2", field: "correctionComment2" },
      { headerName: "Charactor1", field: "charactor1" },
      { headerName: "Charactor2", field: "charactor2" },
      { headerName: "Charactor3", field: "charactor3" },
      { headerName: "Charactor4", field: "charactor4" },
      { headerName: "Charactor5", field: "charactor5" },
      { headerName: "Charactor6", field: "charactor6" },
      { headerName: "Charactor7", field: "charactor7" },
      { headerName: "Charactor8", field: "charactor8" },
      { headerName: "Charactor9", field: "charactor9" },
      { headerName: "Charactor10", field: "charactor10" },
      { headerName: "Number1", field: "number1" },
      { headerName: "Number2", field: "number2" },
      { headerName: "Number3", field: "number3" },
      { headerName: "Number4", field: "number4" },
      { headerName: "Number5", field: "number5" },
      { headerName: "Number6", field: "number6" },
      { headerName: "Number7", field: "number7" },
      { headerName: "Number8", field: "number8" },
      { headerName: "Number9", field: "number9" },
      { headerName: "Number10", field: "number10" },
      { headerName: "UnitMultiplePrintFLG", field: "unitMultiplePrintFLG" },
      { headerName: "SalePricePrintFLG", field: "salePricePrintFLG" },
      { headerName: "ClaimConversionFLG", field: "claimConversionFLG" },
      { headerName: "ReceiveDataFLG", field: "receiveDataFLG" },
      { headerName: "OrderDataFLG", field: "orderDataFLG" },
      { headerName: "DeleteFLG", field: "deleteFLG" },
      { headerName: "OriginalFLG", field: "originalFLG" },
      { headerName: "FLG1", field: "fLG1" },
      { headerName: "FLG2", field: "fLG2" },
      { headerName: "FLG3", field: "fLG3" },
      { headerName: "InputDiv", field: "inputDiv" },
      { headerName: "MakingDate", field: "makingDate", filter: "agDateColumnFilter", type: "dpDateColumn" },
      { headerName: "EditDate", field: "editDate", filter: "agDateColumnFilter", type: "dpDateColumn" },
      { headerName: "EditUserID", field: "editUserID" },
      { headerName: "EditPCID", field: "editPCID" },
      { headerName: "EditProgramID", field: "editProgramID" },
      { headerName: "EditOSUserID", field: "editOSUserID" },
      { headerName: "EditUserComputerName", field: "editUserComputerName" },
      { headerName: "ProduceCommodityCD", field: "produceCommodityCD" },
      { headerName: "PrefectureCode", field: "prefectureCode" },
      { headerName: "CountryCode", field: "countryCode" },
      { headerName: "ProductionArea", field: "productionArea" },
      { headerName: "WaterZoneCode", field: "waterZoneCode" },
      { headerName: "WaterZoneName", field: "waterZoneName" },
      { headerName: "OriginArea", field: "originArea" },
      { headerName: "CommodityGrade", field: "commodityGrade" },
      { headerName: "CommoditySizeClass", field: "commoditySizeClass" },
      { headerName: "CommodityBrand", field: "commodityBrand" },
      { headerName: "CommodityPromotion", field: "commodityPromotion" },
      { headerName: "BiotechnologyKbn", field: "biotechnologyKbn" },
      { headerName: "UnitWeightCode", field: "unitWeightCode" },
      { headerName: "CommodityWeight", field: "commodityWeight" },
      { headerName: "PackingQuantity", field: "packingQuantity" },
      { headerName: "BreedCode", field: "breedCode" },
      { headerName: "CultivationDiv", field: "cultivationDiv" },
      { headerName: "DefrostDiv", field: "defrostDiv" },
      { headerName: "PreservationDiv", field: "preservationDiv" },
      { headerName: "ShapeCode", field: "shapeCode" },
      { headerName: "Usage", field: "usage" },
      { headerName: "OriginOrderNo", field: "originOrderNo" },
      { headerName: "OriginOrderDetailNo", field: "originOrderDetailNo" },
      { headerName: "ShipmentManageDetailNo", field: "shipmentManageDetailNo" },
      { headerName: "OrderWeight", field: "orderWeight" },
      { headerName: "UnitWeight", field: "unitWeight" },
      { headerName: "ShipmentWeight", field: "shipmentWeight" },
      { headerName: "AcceptedWeight", field: "acceptedWeight" },
      { headerName: "ReturnedWeight", field: "returnedWeight" },
      { headerName: "TemporaryDiv", field: "temporaryDiv" },
      { headerName: "StatuteClassifyDiv", field: "statuteClassifyDiv" },
      { headerName: "FLG4", field: "fLG4" },
      { headerName: "FLG5", field: "fLG5" },
      { headerName: "FLG6", field: "fLG6" },
      { headerName: "SendConversionFLG", field: "sendConversionFLG" },
      { headerName: "WorkplaceID", field: "workplaceID" },
      { headerName: "DataKey", field: "dataKey" },
      { headerName: "配送センター", field: "deliveryCenter" },
      { headerName: "PO番号", field: "poNumber" },
      { headerName: "JANCODE", field: "janCode" },
      { headerName: "タイトル", field: "title" },
      { headerName: "商品名", field: "productName" },
      { headerName: "発注締切", field: "orderClosingDate", filter: "agDateColumnFilter", type: "dpFullDateColumn" },
      { headerName: "発売日", field: "releaseDate" },
      { headerName: "販売状況", field: "resaleOrSoldout" },
      { headerName: "商品明細", field: "productDetail" },
      { headerName: "Box入数", field: "piecesPerBox", type: "dpNumericColumn" },
      { headerName: "在庫数", field: "stockQuantity", type: "dpNumericColumn" },
      { headerName: "OrderDiv", field: "orderDiv" },
      { headerName: "OrderDiv2", field: "orderDiv2" },
      { headerName: "ShipCD", field: "shipCD" },
      { headerName: "SSCC", field: "sscc" },
      { headerName: "備考", field: "remarks" },
      { headerName: "納品開始", field: "deliveryStartDate", filter: "agDateColumnFilter", type: "dpFullDateColumn" },
      { headerName: "納品締切", field: "deliveryEndDate", filter: "agDateColumnFilter", type: "dpFullDateColumn" },
    ],
    notifyRecords: [],
    selectionRows: [],
    reduceDialog: false,
    reduceModel: {
      shipmentQuantity: null,
      newShipmentQuantity: null,
    },
    confirmColumns: [
      { headerName: "PO番号", field: "po", pinned: "left" },
      { headerName: "JANCODE", field: "jancode" },
      { headerName: "タイトル", field: "title" },
      { headerName: "商品名", field: "productName" },
      { headerName: "ShipmentQuantity", field: "shipmentQuantity", type: "dpNumericColumn" },
      { headerName: "RecID", field: "recId", hide: true },
      { headerName: "ID", field: "id", hide: true },
    ],
    errorColmuns: [
      { headerName: "RecID", field: "recID" },
      {
        headerName: "エラー内容",
        field: "errorMessage",
        wrapText: true,
        autoHeight: true,
        cellRenderer: function (param) {
          return param.data.errorMessage.join("<br>");
        },
      },
    ],
    gridStyle: { height: "95%" },
  }),
  mounted() {
    this.onSearchClick();
    this.handleResize();
    // 画面解像度による画面サイズ取得
    this.gridStyle.height = this.gridHeightSize + "px";
    window.addEventListener("resize", this.handleResize);
  },
  watch: {
    selectedPageSize(size) {
      this.gridOptions.api.paginationSetPageSize(size);
    },
    globalPageSize(size) {
      this.gridOptions.api.paginationSetPageSize(size);
      this.handleResize();
    },
    gridHeightSize(value) {
      this.gridStyle.height = value + "px";
    },
  },
  beforeMount() {
    this.gridOptions.paginationPageSize = this.globalPageSize;
  },
  computed: {
    selectedRow() {
      return this.selectionRows.length === 1 ? this.selectionRows[0] : null;
    },
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }
      return getDisplayDetails2(this.selectedRow.id, this.detailColumns, this.gridOptions.api);
    },
  },
  methods: {
    clearFilters() {
      this.gridOptions.api.setFilterModel(null);
      this.gridOptions.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },

    async onSearchClick() {
      try {
        this.loadingOn();
        this.selectionRows = [];
        const response = await this.$store.dispatch("amazon/searchNotification");
        console.log("ShippingNotification::onSearchClick", response);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        this.gridOptions.api.setRowData(result.notifications);
        const allColumnIds = this.gridOptions.columnApi.getAllColumns().map((column) => column.colId);
        this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
        this.notifyRecords = result.notifications;
      } finally {
        this.loadingOff();
      }
    },
    onCommitClick() {
      this.$refs.confirmGrid.open({
        title: "事前出荷通知確定",
        columns: this.confirmColumns,
        records: this.selectionRows,
        onSubmit: async (records) => {
          try {
            this.loadingOn();
            let condition = [];
            // frozenされているため？時々エラー（Cannot assign to read only property）が発生する。
            // その対応としてリストを作成しなおす。
            let escapeRecords = [...records];
            escapeRecords.sort((a, b) => {
              if (a.receiveDate !== b.receiveDate) {
                if (a.receiveDate > b.receiveDate) return 1;
                if (a.receiveDate < b.receiveDate) return -1;
              }
              if (a.sEQ1 !== b.sEQ1) {
                if (a.sEQ1 > b.sEQ1) return 1;
                if (a.sEQ1 < b.sEQ1) return -1;
              }
              if (a.sEQ2 !== b.sEQ2) {
                if (a.sEQ2 > b.sEQ2) return 1;
                if (a.sEQ2 < b.sEQ2) return -1;
              }
              return 0;
            });
            escapeRecords.forEach((record) => {
              condition.push({
                recId: String(record.recId),
                lastUpdateDatetime: record.editDate ? record.editDate : null,
              });
            });
            // 最大件数チェック
            const validDataSizeRet = await RequestUtils.validDataSize("C080503", condition.length, (limitSize) => {
              this.$dialog.notify.error(`最大処理件数（${limitSize}件）オーバーのため処理出来ませんでした。`, {
                timeout: 2300,
              });
              this.loadingOff();
            });
            if (!validDataSizeRet) {
              return;
            }
            // バッチ化のためListを直接送信するよう変更
            //const response = await this.$store.dispatch("amazon/comfirmNofitication", { notifications: condition });
            const response = await this.$store.dispatch("amazon/comfirmNofitication", condition);
            console.log("ShippingNotification::onCommitClick", response);
            let error = response.data?.header;
            // 一覧更新レコード
            let errorRows = [];
            switch (error.resultCode) {
              case ApiStatus.consts.SUCCESS:
              case ApiStatus.consts.BUSINESS_ERROR:
              case ApiStatus.consts.ALREADY_CHANGED:
                // エラーメッセージ格納
                if (error.messages) {
                  Object.keys(error.messages).forEach((key) => {
                    errorRows.push({
                      recID: key,
                      errorMessage: error.messages[key],
                    });
                  });
                }
                // エラー表示
                if (errorRows.length > 0) {
                  console.log("PurchaseOrderSearch::onCommitClick", errorRows);
                  // 確認ダイアログから更新完了分のデータを削除する(画面閉じる？)
                  this.$refs.errorGrid.open({ records: errorRows });
                  this.$dialog.notify.error(`事前出荷通知確定処理に失敗したデータが存在します。ご確認ください。`, {
                    timeout: 2300,
                  });
                } else {
                  this.$dialog.notify.info(`選択された事前出荷通知データを確定しました`, { timeout: 2300 });
                  this.$refs.confirmGrid.close();
                }
                break;
              default:
                this.redirectError();
                break;
            }
            this.onSearchClick();
          } catch (error) {
            console.error("PurchaseOrderSearch::onCommitClick", error);
            this.apiRequestError(error);
          } finally {
            this.loadingOff();
          }
        },
      });
    },
    onExportClick() {
      let allColumnIds = [];
      this.gridOptions.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.gridOptions.api.exportDataAsCsv({
        allColumns: true,
        onlySelected: this.selectionRows.length > 0,
        fileName: `事前出荷通知.csv`,
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    onSelectionChanged() {
      this.selectionRows = this.gridOptions.api.getSelectedRows();
    },
  },
};
</script>
