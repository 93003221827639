import { createDefault } from "../../utils/ConstUtils";

export default createDefault([
  { value: 1, text: "確保待ち", enum: "NEW" },
  { value: 2, text: "出荷確定待ち", enum: "WAITING" },
  { value: 3, text: "出荷確定", enum: "CONFIRMED" },
  { value: 4, text: "出荷済み", enum: "SHIPPED" },
  { value: 5, text: "キャンセル", enum: "CANCELED" },
]);

// export default createDefault([
//   { value: 1, text: "新規登録", enum: "NEW" },
//   { value: 2, text: "確保待ち", enum: "INSURE_WAIT" },
//   { value: 3, text: "入金待ち", enum: "PAYMENT_WAIT" },
//   { value: 4, text: "出荷確定待ち", enum: "SHIPMENT_CONFIRMED_WAIT" },
//   { value: 5, text: "出荷確定", enum: "SHIPMENT_CONFIRMED" },
//   { value: 6, text: "出荷済み", enum: "SHIPPED" },
//   { value: 7, text: "キャンセル", enum: "CANCELED" },
// ]);
